export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        id: 'desktop-index',
        name: 'Escritorio',
        to: '/home',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        id: 'mainmnu-index',
        _children: ['Menu Principal']
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"permission-index",
        name: 'Accesos',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            style: "display:none",
            id:"permission-index",
            name: 'Permisos',
            to: '/accesos/permisos'
          },
          {
            style: "display:none",
            id:"role-index",
            name: 'Tipo Usuario',
            to: '/accesos/tipos'
          },
          {
            style: "display:none",
            id: "user-index",
            name: 'Usuarios',
            to: '/accesos/usuarios'
          },  
          {
            style: "display:none",
            id: "permission-index",
            name: 'Permisos Asignados',
            to: '/accesos/permisos_asignados'
          },
        ]
      },
      /** mantenimientos */
      {
        _name: 'CSidebarNavDropdown',
        id:"maintenance-index",
        name: 'Mantenimientos',
        route: '/maintenance',
        icon: 'cil-cog',
        items: [
          {
            style: "display:none",
            id:"maintenance-services",
            name: 'Procedimientos',
            to: '/mantenimientos/procedimientos'
          },
          {
            style: "display:none",
            id:"maintenance-machine",
            name: 'Máquinas',
            to: '/mantenimientos/maquinas'
          },
          {
            style: "display:none",
            id:"maintenance-supplies",
            name: 'Insumos',
            to: '/mantenimientos/insumos'
          },
          {
            style: "display:none",
            id:"maintenance-client",
            name: 'Clientes',
            to: '/mantenimientos/clientes'
          },
        ]
      },
      /** caja */
      {
        _name: 'CSidebarNavItem',
        id: 'bead-index',
        name: 'Caja',
        to: '/bead',
        icon: 'cil-speedometer',
      },
      /** citas */
      {
        _name: 'CSidebarNavItem',
        id: 'appointment-index',
        name: 'Citas',
        to: '/citas',
        icon: 'cil-speedometer',
      },
      /** procesos */
      {
        _name: 'CSidebarNavDropdown',
        id:"proccess-index",
        name: 'Procesos',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            style: "display:none",
            id:"proccess-purchase",
            name: 'Compras',
            to: '/procesos/compras'
          },
        ]
      },
      /** sorteos */
      // {
      //   _name: 'CSidebarNavItem',
      //   id: 'desktop-index',
      //   name: 'Sorteos',
      //   to: '/sorteos',
      //   icon: 'cil-speedometer',
      // },
    ]
  }
]